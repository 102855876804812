import React, {useState} from 'react'

import {GoogleMap, InfoWindow, Marker, useJsApiLoader, useLoadScript} from '@react-google-maps/api';

import {ReactComponent as Logo} from '../assets/img/svg/logo.svg';


function MyMap(props) {

  const {isLoaded} = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyA16TsCmSRZlT_VazIE4kF_izuvA6ZLMxY",
    language: navigator.language || navigator['userLanguage']
  });

  const [map, setMap] = useState(null);
  const [showingInfoWindow, setShowingInfoWindow] = useState(true);

  const [zoom, setZoom] = useState(17);
  const [center, setCenter] = useState();

  const onMarkerClick = (marker) => {
    setShowingInfoWindow(true);
  }

  const onInfoWindowClose = () => {
    if (showingInfoWindow) {
      setShowingInfoWindow(false);
    }
  }


  const getMapStyle = () => {
    return [
      {
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#f5f5f5"
          }
        ]
      },
      {
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#616161"
          }
        ]
      },
      {
        "elementType": "labels.text.stroke",
        "stylers": [
          {
            "color": "#f5f5f5"
          }
        ]
      },
      {
        "featureType": "administrative.land_parcel",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#bdbdbd"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#eeeeee"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#757575"
          }
        ]
      },
      {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#e5e5e5"
          }
        ]
      },
      {
        "featureType": "poi.park",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#9e9e9e"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#ffffff"
          }
        ]
      },
      {
        "featureType": "road.arterial",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#757575"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#dadada"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#616161"
          }
        ]
      },
      {
        "featureType": "road.local",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#9e9e9e"
          }
        ]
      },
      {
        "featureType": "transit.line",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#e5e5e5"
          }
        ]
      },
      {
        "featureType": "transit.station",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#eeeeee"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#c9c9c9"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#9e9e9e"
          }
        ]
      }
    ];
  };

  return isLoaded ? (
      <GoogleMap
          id={'map'}
          options={{
            fullscreenControl: true,
            scrollwheel: false,
            controlSize: 30,
            styles: getMapStyle(),
            mapTypeId: (!!window.google && window.google.maps.MapTypeId.TERRAIN) || null
          }}
          mapContainerStyle={{
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            position: 'relative'
          }}
          center={props.center}
          zoom={zoom}
      >
          {
            Object.keys(props.markers).map((i) => {
                  return <Marker
                      key={'__' + i}
                      onClick={() => onMarkerClick(props.markers[i])}
                      name={props.markers[i]['name']}
                      icon={{
                        scaledSize: new window.google.maps.Size(40, 50),
                        url: 'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" baseProfile="tiny" overflow="visible" viewBox="0 0 512 512" fill="' + props.color.replace('#', '%23') + '"><path d="M256 0C161.9 0 85.3 76.6 85.3 170.7c0 28.3 7.1 56.3 20.5 81.1l140.8 254.7c1.9 3.4 5.4 5.5 9.3 5.5 3.9 0 7.5-2.1 9.3-5.5l140.9-254.8c13.4-24.8 20.4-52.8 20.4-81C426.7 76.6 350.1 0 256 0zm0 256c-47.1 0-85.3-38.3-85.3-85.3S209 85.4 256 85.4s85.3 38.3 85.3 85.3S303.1 256 256 256z"/></svg>',
                        origin: new window.google.maps.Point(0, 0),
                        anchor: new window.google.maps.Point(20, 50)
                      }}
                      position={{
                        lat: props.markers[i].lat,
                        lng: props.markers[i].lng
                      }}
                    >
                    {showingInfoWindow &&
                        <InfoWindow key={'_'} onCloseClick={onInfoWindowClose} position={{
                          lat: props.markers[i].lat,
                          lng: props.markers[i].lng
                        }}>
                          <div className={'infoBox'}>
                            <Logo/>
                          </div>
                        </InfoWindow>
                    }
                  </Marker>
                }
            )
          }

      </GoogleMap>
  ) : <></>
}

export default React.memo(MyMap)