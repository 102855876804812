import React, {Component, useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';

import MyMap from "./map";

import LazyImage from "./lazyImage.class";

import '../assets/scss/map.scss';

import {ReactComponent as MapPin} from '../assets/img/svg/Pin.svg';

import {Cookies, getCookieConsentValue, resetCookieConsentValue} from "react-cookie-consent";

const Map = (props) => {
  const [markers, setMarkers] = useState({});
  const [center, setCenter] = useState(null)

  useEffect(() => {
    let points = {};

    if (!!props['data']) {
      props['data'].forEach((data, key) => {
        points[key] = {
          lat: parseFloat(data['Latitude']),
          lng: parseFloat(data['Longitude']),
          data: {
            name: data['Name'],
            street: data['Straße'],
            city: data['Stadt'],
          },
        };
      });
    }

    console.log(points)
    if (Object.keys(points).length > 1) {
      setMarkers(points);
    } else {
      setCenter(points[0]);
      setMarkers(points);
    }
  }, []);


  let data = props['data'][0];

  return (
      <>

        <div id={props.id} className={'mapBox'}>

          <div className={'horizontalLine'}/>

          <div className={'headlines'}>
            <h2>
              {data['Hauptueberschrift']}
            </h2>

            <h3>
              {data['Unterueberschrift']}
            </h3>
          </div>

          <div className="horizontalShadow"/>

          <div className={'location'}>

            <div className={'left'}>
              <div className={'image'}>
                <LazyImage dkey={'location_img'} className={'img'} alt={'location_img'}
                           src={'image.php?file=' + data['Bild'] + '&height=200&width=360'}/>
              </div>

              <div className={'text'}>
                <h5>{data['Name']}</h5>
                <div>
                                    <span>
                                        Telefon: {data['Telefon']}
                                    </span>
                </div>

                <div>
                  <a href={'mailto:' + data['Mail']}>
                    E-Mail: {data['Mail']}
                  </a>
                </div>
              </div>
            </div>

            <div className={'right'}>
              <address>
                <h5 className={'company'}>
                  {data['Firma']}
                </h5>

                <div className={'street'}>
                  {data['Straße']}
                </div>

                <div>{data['Stadt']}</div>
              </address>

              <MapPin/>
            </div>

          </div>


          <div className={'mapBoxInner'}>
            {!!getCookieConsentValue() && getCookieConsentValue() !== 'false' ?
                <MyMap markers={markers} center={center} {...props} /> :

                <React.Fragment>
                  <div className={'loadMap'}>
                    <h2>Karte anzeigen</h2>
                    <h4>
                      BITTE KLICKEN, UM DIE GOOGLE KARTE ANZUZEIGEN.<br/>
                      DABEI WERDEN GERÄTEDATEN AUSGETAUSCHT.
                    </h4>
                    <button
                        className={'button'}
                        type={'button'}
                        onClick={() => resetCookieConsentValue()}
                    >Google Maps anzeigen
                    </button>
                  </div>
                </React.Fragment>
            }

          </div>

        </div>
      </>
  );
}

export default Map;
