import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

import '../assets/scss/tabs.scss';

import marked from './markdown';
import ImageSlider from "./imageSlider.class";
import ImageTiles from "./imageTiles.class";

import {shuffle} from "./sliderFunctions";
import Lightbox from "./simplelightbox.class";


class About extends Component {

    constructor(props) {
        super(props);

        this.state = {
            current: props.data.filter(h => h['Typ'] !== 'Hauptueberschrift' && h['Typ'] !== 'Unterueberschrift' && h['Typ'] !== 'Hintergrundbild' && h['Typ'] !== 'Logo')[0]['Typ'],
            groups: {},
            lightboxImage: null
        };

        this.toggleLightbox = this.toggleLightbox.bind(this);
    }


    show(key) {
        this.setState({
            current: key
        });
    }

    switchSelect(e) {
        let target = e.target;

        this.setState({
            current: target.value
        });
    }

    group(elms) {
        let groups = {};

        if (!!elms && elms.length) {
            elms.forEach(e => {
                groups[e['Typ']] = e;

                let images = !!!!e['Bild'] ? e['Bild'].split(',') : [undefined];

                groups[e['Typ']]['Bild'] = shuffle(images);
            })
        }

        return groups;
    }

    toggleLightbox(image = null) {
        if (image !== null) {
            this.setState({
                lightboxImage: image
            });
        } else {
            this.setState({
                lightboxImage: null
            });
        }
    }

    render() {

        if (!!this.props.data) {

            let headline = this.props.data.filter(h => h['Typ'] === 'Hauptueberschrift')[0];
            let subheadline = this.props.data.filter(h => h['Typ'] === 'Unterueberschrift')[0];

            let background = this.props.data.filter(h => h['Typ'] === 'Hintergrundbild')[0];
            background = (!!background && !!background['Bild']) ? background['Bild'] : null;

            return (
                <div id={this.props.id} className={'tabContainer'}>

                    <div className={'headlines'}>
                        <h2>
                            {headline['Text']}
                        </h2>

                        <h3>
                            {subheadline['Text']}
                        </h3>
                    </div>

                    {(!!this.state.groups && Object.keys(this.state.groups).length > 1) &&
                        <>
                            <div className={'tabSelect'}>
                                <select onChange={e => this.switchSelect(e)} value={this.state.current}>
                                    {this.state.groups &&
                                    Object.keys(this.state.groups).map((e, key) => {
                                        return <option value={this.state.groups[e]['Typ']} key={'tab_' + key}>{this.state.groups[e]['Tab'].replace('<br>', '')}</option>;
                                    })
                                    }
                                </select>
                            </div>

                            <ul className={'tabs'}>
                                {!!this.state.groups &&
                                Object.keys(this.state.groups).map((e, key) => {
                                    return <li className={(this.state.current === this.state.groups[e]['Typ'] ? 'current': '')} onClick={() => this.show(this.state.groups[e]['Typ'])} key={'tab_' + key} dangerouslySetInnerHTML={{__html: !!this.state.groups[e]['Tab'] ? marked(this.state.groups[e]['Tab']) : ''}} />;
                                })
                                }
                            </ul>
                        </>
                    }


                    <div className={'horizontalShadow'} />

                    <div className={'tabBackgroundBox'} style={background ? {backgroundImage:'url(assets/' + background + ')'} : {}}>
                        <div className={'tabContent'}>

                            {!!this.state.groups &&
                            Object.keys(this.state.groups).map((e, key) => {
                                let group = this.state.groups[e];

                                let pos = (key % 2) ? 'left ' : 'right ';

                                let images = group['Bild'];

                                return (
                                    <div className={'tab box ' + pos + (this.state.current===group['Typ'] ? 'current': '')} key={'tab_' + key}>
                                        <div className="textBox">
                                            <div className="textBoxInner">
                                                <p className="text" dangerouslySetInnerHTML={{__html: !!group['Text'] ? marked(group['Text']) : ''}} />
                                            </div>
                                        </div>

                                        <div className={'imageBox'}>
                                            {(!!images && images.length && group['Bildtyp'] === 'Kacheln') ?
                                                <ImageTiles images={images} id={this.props.id} tab={group['Typ']} forceLoad={this.state.current===group['Typ']} toggleLightbox={this.toggleLightbox} />
                                                :
                                                <ImageSlider images={images} id={this.props.id} tab={group['Typ']} forceLoad={this.state.current===group['Typ']} toggleLightbox={this.toggleLightbox} />
                                            }

                                            {this.state.lightboxImage !== null &&
                                                <Lightbox current={this.state.lightboxImage} images={images} toggleLightbox={this.toggleLightbox} />
                                            }
                                        </div>
                                    </div>
                                );

                            })
                            }

                        </div>
                    </div>

                    <div className="horizontalLine" />

                </div>
            );
        } else {
            return <></>;
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.tab !== this.props.tab && Object.keys(this.state.groups).includes(this.props.tab)) {
            this.setState({
                current: this.props.tab,
            })
        }
    }

    componentDidMount() {
        let filtered = this.props.data.filter(h => { return h['Typ'] !== 'Hauptueberschrift' && h['Typ'] !== 'Unterueberschrift' && h['Typ'] !== 'Hintergrundbild' && h['Typ'] !== 'Logo'});
        this.setState({
            groups: this.group(filtered)
        })
    }
}

export default withRouter(About);
