import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

import {CSSTransition} from "react-transition-group";

import '../assets/scss/header.scss';

import {ReactComponent as LogoWhite} from '../assets/img/svg/logo_white.svg';
import {ReactComponent as LogoDark} from '../assets/img/svg/logo.svg';

import {ReactComponent as Yt} from '../assets/img/svg/youtube.svg';
import {ReactComponent as Instagram} from '../assets/img/svg/instagram.svg';
import {ReactComponent as Webcam} from '../assets/img/svg/webcam.svg';
import {ReactComponent as Link} from '../assets/img/svg/link.svg';


class TopBar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            open: false
        };

        // this.scroll();
        this.clickAndScroll = this.clickAndScroll.bind(this);
        this.openMenu = this.openMenu.bind(this);
    }

    scroll() {
        window.addEventListener('scroll', () => {
            let topDistance = window.scrollY;
            let logo = document.querySelector(".logo");
            let start = document.querySelector("#start");

            if(!!start) {
                let startProps = start.getBoundingClientRect();

                if (!!logo) {
                    logo.style.opacity = topDistance / (startProps.height/3);
                }
            }
        });
    }

    clickAndScroll(e, id, tab = null) {
        e.preventDefault();

        let elm = document.querySelector(id);

        let header = document.querySelector('header');
        let headerData = header.getBoundingClientRect();


        if (!!elm) {
            let position = elm.offsetTop - headerData.height;

            window.scroll({
                top: position,
                behavior: 'smooth'
            });
        }

        this.setState({
            open: false
        });

        if (!!tab) {
            this.props.setTab(id, tab);
        }

    }

    openMenu() {
        let state = !this.state.open;

        this.setState({open: state});
    }

    componentDidMount() {
        const bottomBox = document.querySelector('.header .bottom');

        let timeout = null;

        document.addEventListener('mousemove', function() {
            bottomBox.classList.remove('hide');
            clearTimeout(timeout);

            timeout = setTimeout(() => {
                console.log('stop moving')
                bottomBox.classList.add('hide');
            }, 4000);
        });
    }

    render() {

        let menu = [];

        if (!!this.props.data) {
            this.props.data.forEach((d) => {
                if (!!d['Tab']) {
                    let m = menu.filter(e => e['id'] === d['Link']);

                    if (m.length) {
                        m[0]['tabs'].push({
                            id: d['Link'],
                            name: d['Name'],
                            tab: d['Tab']
                        });
                    }
                } else {
                    menu.push({
                        id: d['Link'],
                        name: d['Name'],
                        tabs: []
                    });
                }
            });
        }

        return (
            <>
                <header className="header">

                    <div className={'top'}>
                        <div>
                            <strong>Telefon:</strong> {!!this.props['contact'] && !!this.props['contact']['Telefon'] ? this.props['contact']['Telefon'] : ''}</div>
                        <div>
                            <strong>E-Mail:</strong> {!!this.props['contact'] && !!this.props['contact']['Mail'] ? <a href={'mailto:' + this.props['contact']['Mail']}>{this.props['contact']['Mail']}</a> : ''}</div>
                    </div>

                    <div className={'bottom'} style={{backgroundImage: `url('assets${this.props.config.menuBackground}')`}}>
                        <nav>
                            <div className={'logoBox'}>
                                <div className={'logo'}>
                                    <LogoWhite onClick={e => this.clickAndScroll(e, '#start')} title={'Startseite'} />
                                </div>
                            </div>

                            <div className={'centerBox'}>
                                <ul>
                                    {menu.map(m => {
                                        return (
                                            <li key={'top_' + m['id']}>
                                                <a href={'#' + m['id']} onClick={e => {
                                                    if (m['id'] === 'impressum') {
                                                        e.preventDefault();

                                                        this.props.showImprint();
                                                    } else {
                                                        this.clickAndScroll(e, '#' + m['id']);
                                                    }
                                                }}>
                                                    {m['name']}
                                                </a>
                                                {(m['tabs'].length > 0) &&
                                                    <ul>
                                                        {
                                                            m['tabs'].map(s => {
                                                                return (
                                                                    <li key={'sub_' + s['id'] + '_' + s['tab']}>
                                                                        <a href={'#' + m['id']} onClick={e => this.clickAndScroll(e, '#' + m['id'], s['tab'])}>
                                                                            {s['name']}
                                                                        </a>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                }
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>

                            <div className={'flex socials'}>
                                {this.props['youtube'] &&
                                    <a href={!!this.props['youtube'] ? this.props['youtube'] : ''} target={'_blank'} className={'yt'}>
                                        <Yt />
                                    </a>
                                }

                                {!!this.props['instagram'] &&
                                    <a href={!!this.props['instagram'] ? this.props['instagram'] : ''} target={'_blank'} className={'instagram'}>
                                        <Instagram />
                                    </a>
                                }
                                {!!this.props['webcam'] &&
                                    <a href={!!this.props['webcam'] ? this.props['webcam'] : ''} target={'_blank'} className={'webcam'}>
                                        <Webcam />
                                    </a>
                                }

                                {!!this.props['link'] &&
                                    <a href={!!this.props['link'] ? this.props['link'] : ''} target={'_blank'} className={'link'}>
                                        <Link />
                                    </a>
                                }
                            </div>

                        </nav>

                        <div className={'menu'} onClick={() => this.openMenu()}>
                            <div className={'menuBox ' + (this.state.open ? 'opened' : '')}>
                                <span className="line_1" />
                                <span className="line_2" />
                                <span className="line_3" />
                            </div>
                        </div>
                    </div>

                </header>

                <CSSTransition in={this.state.open === true} timeout={200} classNames={'overlay'}>
                    <div id={'overlay'} className={(this.state.open ? 'opened' : '')} onClick={() => this.setState({open: false})} />
                </CSSTransition>

                <div id={'mainMenu'} className={(this.state.open ? 'opened' : '')}>

                    <div className={'logoBox'}>
                        <LogoDark />
                    </div>

                    <ul>
                        {menu.map(m => {
                            return (
                                <li key={'top_' + m['id']}>
                                    <a href={'#' + m['id']} onClick={(e) => {
                                        if (m['id'] === 'impressum') {
                                            e.preventDefault();

                                            this.props.showImprint();
                                        } else {
                                            this.clickAndScroll(e, '#' + m['id']);
                                        }

                                        this.setState({open: false});
                                    }}>
                                        {m['name']}
                                    </a>

                                    {(m['tabs'].length > 0) &&
                                        <ul>
                                            {
                                                m['tabs'].map(s => {
                                                    return (
                                                        <li key={'sub_' + s['id'] + '_' + s['tab']}>
                                                            <a href={'#' + m['id']} onClick={e => this.clickAndScroll(e, '#' + m['id'], s['tab'])}>
                                                                {s['name']}
                                                            </a>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    }
                                </li>
                            );
                        })}
                    </ul>

                </div>
            </>
        );
    }
}

export default withRouter(TopBar);
