import React, {Component} from 'react';

import '../assets/scss/slider.scss';
import marked from "./markdown";

import ImageSlider from "./imageSlider.class";
import ImageTiles from "./imageTiles.class";

import {go, goTo} from './sliderFunctions';

import {ReactComponent as Left} from '../assets/img/svg/left.svg';
import {ReactComponent as Right} from '../assets/img/svg/right.svg';

let timer = null;
let subTimer = null;

class Slider extends Component {

    constructor(props) {
        super(props);

        this.state = {
            headline: '',
            subheadline: '',
            logo: null,
            slides: [],
            imagePosition: '',
            current: 0
        };
    }


    render() {
        let left = '-100%';

        if (this.state.slides.length <= 1) {
            left = 0;
        }

        let background = this.props.data.filter(h => h['Typ'] === 'Hintergrundbild')[0];
        background = (!!background && !!background['Text']) ? background['Text'] : null;

        return (
            <div className={'sliderBoxElm'} id={this.props.id}>

                <div className={'headlines'}>
                    <h2>
                        {this.state.headline['Text']}
                    </h2>

                    <h3>
                        {this.state.subheadline['Text']}
                    </h3>

                </div>

                <div className="horizontalShadow" />

                <div className={'sliderBackgroundBox'} style={background ? {backgroundImage:'url(assets/' + background + ')'} : {}}>
                    <div className={'sliderOuter'}>
                        {(this.state.slides.length > 1) &&
                        <>
                            <div className={'prevSlide'} onClick={() => go('prev', this.props.id, timer, subTimer, this)}>
                                <Left />
                            </div>
                            <div className={'nextSlide'} onClick={() => go('next', this.props.id, timer, subTimer, this)}>
                                <Right />
                            </div>
                        </>
                        }


                        <div className={'slideBox'}>

                            <div className={'slides'} style={{left:left}}>
                                {
                                    (this.state.slides.length > 0) &&
                                    this.state.slides.map((slide, key) => {

                                        let className = '';


                                        if (!!this.state.imagePosition) {
                                            className = (this.state.imagePosition === 'Links') ? ' left' : ' right';
                                        } else {
                                            if (key % 2 === 0) {
                                                className = ' left';
                                            }
                                        }


                                        if (key === 0) {
                                            className += ' current';
                                        } else if ( key === this.state.slides.length-1) {
                                            className += ' prev';
                                        } else if (key !== 0) {
                                            className += ' next';
                                        }

                                        let images = !!slide['Bild'] ? slide['Bild'].split(',') : [undefined];

                                        return (
                                            <div key={'slide_' + key} className={'slide' + className}>
                                                <p className={'text'} dangerouslySetInnerHTML={{__html: (!!slide['Text'] ? marked(slide['Text']) : '')}} />

                                                <div className={'imageBox'}>
                                                    {(!!images && images.length && slide['Bildtyp'] === 'Kacheln') ?
                                                        <ImageTiles images={images} id={this.props.id} tab={slide['Typ']} forceLoad={this.state.current===slide['Typ']} />
                                                        :
                                                        <ImageSlider images={images} id={this.props.id} tab={slide['Typ']} forceLoad={this.state.current===slide['Typ']} />
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>

                {(this.state.slides.length > 1) &&
                    <div className={'bubbleBox'}>
                        {
                            (this.state.slides.length > 1) &&
                                this.state.slides.map((slide, key) => {
                                    return <div key={'bubble_' + key} className={'bubble' + (this.state.current === key ? ' current' : '')} onClick={() => goTo(key, this.props.id, timer, subTimer, this)} />;
                                }
                            )
                        }
                    </div>
                }
            </div>
        );
    }

    componentDidMount() {
        this.setState({
            slides: this.props.data.filter(d => d['Typ'] !== 'Hauptueberschrift' && d['Typ'] !== 'Unterueberschrift' && d['Typ'] !== 'Logo' && d['Typ'] !== 'Hintergrundbild' && d['Typ'] !== 'Bildposition'),
            headline: this.props.data.filter(d => d['Typ'] === 'Hauptueberschrift')[0],
            subheadline: this.props.data.filter(d => d['Typ'] === 'Unterueberschrift')[0],
            logo: this.props.data.filter(d => d['Typ'] === 'Logo')[0],
            imagePosition: this.props.data.filter(d => d['Typ'] === 'Bildposition')[0]['Text']
        });
    }
}

export default Slider;
