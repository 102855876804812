import React, {Suspense, useEffect, useState} from 'react';
import {BrowserRouter as Router} from "react-router-dom";

import Cooks from 'universal-cookie';


import CookieConsent, {
  getCookieConsentValue,
  Cookies,
} from "react-cookie-consent";

import AppRouter from './AppRouter.class';
import Loading from './loading'

import {initGA} from "./components/utils.js";
import {install} from 'ga-gtag';
import {ReactComponent as Logo} from './assets/img/svg/logo.svg';

import '@fontsource/merriweather-sans/300.css';
import '@fontsource/merriweather-sans/300-italic.css';
import '@fontsource/merriweather-sans/600.css';
import '@fontsource/merriweather-sans/600-italic.css';

function App() {
  const [visible, setVisible] = useState(typeof getCookieConsentValue() === 'undefined' && getCookieConsentValue() !== 'true');
  const handleAcceptCookie = () => {
    initGA('UA-165941736-1');
    setVisible(false);
  };
  const handleDeclineCookie = () => {
    //remove google analytics cookies
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
    setVisible(false);
  };

  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      handleAcceptCookie();
    }

    const cookieChangeListener = (c) => {
      const {name, value} = c;
      if (typeof value === "undefined") {
        setVisible(true);
      } else {
        setVisible(value);
      }
    };

    const cook = new Cooks();

    cook.addChangeListener(cookieChangeListener);

    if (visible === true && Cookies.get('bbb_cookie') === '1') {
      loadGoogleScripts();
    }

    return () => {
      cook.removeChangeListener(cookieChangeListener);
    }
  }, []);

  const loadGoogleScripts = () => {
    const gaProperty = 'UA-166792743-2';

    initGA(gaProperty);
    install(gaProperty, { 'send_page_view': false });
  }

  return (
        <Suspense fallback={<Loading />}>
            <Router>
                <AppRouter />
            </Router>

          {visible &&
              <CookieConsent
                  enableDeclineButton
                  onAccept={handleAcceptCookie}
                  onDecline={handleDeclineCookie}
                  buttonText={'Akzeptieren'}
                  declineButtonText={'Ablehnen'}
                  disableStyles={true}
                  contentClasses={'text'}
                  buttonWrapperClasses={'buttons'}
                  overlay={true}
                  overlayClasses={'CookieConsentOverlay'}
              >
                <Logo />
                Diese Webseite verwendet Cookies um die Benutzererfahrung zu verbessern.
              </CookieConsent>
          }
        </Suspense>
    );
}

export default App;
