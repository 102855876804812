import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

import '../assets/scss/imprint.scss';

import marked from "./markdown";


class Imprint extends Component {

    render() {
        return (
            <div className={'imprint_container'}>
                <div id={'impressum'} className={'imprint'}>

                    <div className={'sections'}>
                        <div className={'icon-close close'} onClick={() => {this.props.hide()}} title={'Schließen'} />

                        <section>
                            <strong>IMPRESSUM</strong>

                            <div dangerouslySetInnerHTML={{__html: marked(this.props.data[0]['Links'])}} />
                        </section>

                        <section>
                            <strong>RECHTSHINWEISE / DATENSCHUTZ</strong>

                            <div dangerouslySetInnerHTML={{__html: marked(this.props.data[0]['Rechts'])}} />
                        </section>
                    </div>
                </div>
            </div>
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.show !== this.props.show) {

            let imprint = document.querySelector('#impressum');

            if (this.props.show) {
                imprint.style.maxHeight = '9999px';
            } else {
                imprint.style.maxHeight = '0';
            }

            setTimeout(() => {
                let container = imprint;

                if (!!container) {
                    let position = container.offsetTop;

                    let topBar = document.querySelector('header');
                    let topBarHeight = topBar.getBoundingClientRect();

                    window.scroll({
                        top: position - topBarHeight.height,
                        behavior: 'smooth'
                    });
                }
            }, 200);

        }

        if (prevProps.resetAll !== this.props.resetAll && !!this.props.resetAll) {
            this.props.hide()
        }
    }
}

export default withRouter(Imprint);
