import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

import '../assets/scss/contact.scss';


class Contact extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            sent: false,
            error: false,
            form: {
                name: '',
                street: '',
                location: '',
                phone: '',
                mail: '',
                category: '',
                text: '',
                lolField: '',
                terms: false
            }
        };

        this.change = this.change.bind(this);
        this.send = this.send.bind(this);
    }

    change(e) {
        let form = Object.assign({}, this.state.form, {[e.target.name]: e.target.value});

        this.setState({
            form: form
        });
    }

    changeTerms() {
        let form = Object.assign({}, this.state.form, {'terms': !this.state.form.terms});

        this.setState({
            form: form
        });
    }

    send(e) {
        e.preventDefault();

        let state = this.state.form;

        this.setState({
            loading:true,
            error: false
        });

        console.log(window.location)
        fetch(window.location.href + 'contact.php',{
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                // 'Content-Type': 'application/json;charset=utf-8',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            body: Object.keys(state).map(function(key) {
                return key + '=' + state[key];
            }).join('&')
        })
        .then(response => {
            if (response.ok) {
                this.setState({
                    loading:false,
                    sent: true,
                    error: false
                });
            } else {
                this.setState({
                    loading:false,
                    sent: false,
                    error: true
                });
            }
        })
        .catch(error => {
            this.setState({
                loading:false,
                sent: false,
                error: true
            });
        });
    }

    render() {

        let headline = this.props.data.filter(h => h['Typ'] === 'kontaktformular')[0];
        let subheadline = this.props.data.filter(h => h['Typ'] === 'kontaktformular')[0];
        let background = this.props.data.filter(h => h['Typ'] === 'Hintergrundbild')[0]['Text'];

        return (
            <div id={this.props.id} className={'contact'}>

                <div className={'headlines'}>
                    <h2>
                        {headline['Hauptueberschrift']}
                    </h2>

                    <h3>
                        {subheadline['Unterueberschrift']}
                    </h3>
                </div>

                <div className="horizontalShadow" />

                <div className={'contactBackgroundBox'} style={{backgroundImage:'url(assets/' + background + ')'}}>
                    <div className={'contactBox'}>

                        <form onSubmit={(e) => this.send(e)} method={'post'} className={'box contactForm'}>

                            <div className={'left'}>
                                <input type={'text'} defaultValue={this.state.form.name} name={'name'} placeholder={'IHR NAME*'} required={true} onChange={(e) => this.change(e)} />

                                <input type={'text'} defaultValue={this.state.form.street} name={'street'} placeholder={'ADRESSE'} onChange={(e) => this.change(e)} />

                                <input type={'text'} defaultValue={this.state.form.location} name={'location'} placeholder={'PLZ / ORT'} onChange={(e) => this.change(e)} />

                                <input type={'text'} defaultValue={this.state.form.phone} name={'phone'} placeholder={'TELEFON*'} required={true} onChange={(e) => this.change(e)} />

                                <input type={'mail'} defaultValue={this.state.form.mail} name={'mail'} placeholder={'E-Mail-Adresse*'} required={true} onChange={(e) => this.change(e)} />
                            </div>

                            <div className={'right'}>

                                <div className={'selectBox'}>
                                    <div className={'head'}>IHRE NACHRICHT AN UNS</div>
                                </div>

                                <textarea name={'text'} defaultValue={this.state.form.text} placeholder={'Bitte geben Sie hier ihre Nachricht ein...*'} required={true} onChange={(e) => this.change(e)} />

                                <input type="text" defaultValue={this.state.form.lolField} name="lolField" className="secCheck" title="Feld" onChange={(e) => this.change(e)} />

                                <div className={'checkbox'}>
                                    {this.state.form.terms}
                                    <input type={'checkbox'} id={'terms'} name={'terms'} required={true} onChange={(e) => this.changeTerms()} />
                                    <label htmlFor={'terms'}>Datenschutzerklärung gelesen</label>
                                </div>

                                <div className={'error ' + (this.state.error ? 'visible' : '')}>
                                    Ein Fehler ist aufgetreten<br />
                                    Bitte Versuchen Sie es später erneut.
                                </div>

                                {!this.state.sent ?
                                    <button type={'submit'} disabled={!this.state.form.terms}>
                                        {this.state.loading ?
                                            <div className={'loader'}/> :
                                            'Nachricht senden'
                                        }
                                    </button>
                                    :
                                    <div className={'ok'}>
                                        Vielen Dank für die Kontaktaufnahme. <br/>
                                        Wir melden uns in Kürze bei Ihnen.
                                    </div>
                                }
                            </div>

                        </form>
                    </div>
                </div>

            </div>
        );
    }

    componentDidMount() {
        this.setState({
            headline: this.props.data.headline,
            subheadline: this.props.data.subheadline,
        });
    }
}

export default withRouter(Contact);
